
function TagsInput(props){
    return (
        <div>
      <label className="uppercase font-ibm">Tags</label>
        <div className="tags-input-container rounded-full">
            {props.tags.map((tag, index) => (
                <div className="tag-item" key={index}>
                    <span className="text">{tag}</span>
                    <span className="close" onClick={() => props.removeTag(index)}>&times;</span>
                </div>
            )) }
            <input type="text" className="tags-input" placeholder="Tags" onKeyDown={props.handleKeyDown} />
        </div>
        </div>
    )
}

export default TagsInput;