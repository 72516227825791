const Cancel = ({ state , close, stop}) => {
    if(state === false) return null;
    else {
    return <div className="fixed flex justify-center items-center inset-0 bg-black bg-opacity-50">
       <div className="py-6 px-4 mx-8 w-full relative  bg-white rounded-xl text-center">
            <div className="flex justify-end">
                <button className="font-bold" onClick={close}>x</button>
            </div>
            <h3 className="font-ibm uppercase font-bold">Finish stream</h3>
            <h4 className="font-ibm">Are you sure? </h4>
            <button onClick={stop} className="btn-principal font-semibold w-11/12 rounded-full py-3 px-8 mt-6">Yes, finish stream</button>
            <button onClick={close} className="btn-secundario font-semibold w-11/12 rounded-full py-3 px-8 mt-2">No, continue stream</button>
       </div>
    </div>
    }
}
export default Cancel;