const PopUp = ({ state, close }) => {
    if(state === false) return null;
    else {
    return <div className="fixed flex justify-center items-center inset-0 bg-black bg-opacity-50">
       <div className="py-8 px-10 mx-8 w-full relative h-56 bg-white rounded-xl text-center">
            <h3 className="font-ibm uppercase font-bold">You must to enable position to start the stream</h3>
            <button onClick={() => window.location.reload()} className="btn-principal w-full rounded-full py-3 px-8 mt-6">reload</button>
            <button onClick={() => close()} className="btn-principal w-full rounded-full py-3 px-8 mt-6">close</button>
       </div>
    </div>
    }
}
export default PopUp;