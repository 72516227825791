import { useState, useEffect } from "react";
import { StartCamera } from "../utils/peekast";
import PopUp from "../components/PopUp";
const Home = (props) => {
    const [ isAvailable, setIsAvailable ] = useState(true);
    const [ toggleModal, setToggleModal ] = useState(false);
    // useEffect(() => {
    //   const error = (err) => {
    //         console.log(err)
    //         setToggleModal(true)
    //   }
    //   const success  = (response) => {
    //         props.setCoordinates(response.coords)
    //         setIsAvailable(true)
    //   }
    //   const options = {
    //     enableHighAccuracy: true,
    //     timeout: 5000,
    //     maximumAge: 0
    //   };
    //   navigator.geolocation.getCurrentPosition(success, error, options);
    // }, [props.setCoordinates])
    const initStream = async () => {
      const camera = await StartCamera();
      props.setStreamProps({camera})
      props.start()
    }
    return (
          <div className="h-screen flex justify-center text-center items-center flex-col px-12 space-y-4">
            <h3 className="font-4xl font-poppins font-bold">STREAM</h3>
            <h5 className="font-xl font-ibm">Start streaming any content you want to show to the word.</h5>
            <button className="btn-principal rounded-full uppercase py-3 px-8 disabled:cursor-not-allowed" 
            disabled={!isAvailable} onClick={initStream}>start streaming</button>
            <PopUp state={toggleModal} close={() => setToggleModal(false)}></PopUp>
          </div>
    )
}
export default Home;
