import  RecordRTC,{ MediaStreamRecorder, WebAssemblyRecorder } from 'recordrtc';
const PeekastEndpoint = "https://poc.services.peekast.com/api/v1/streams";


async function StartCamera() {
    return navigator.mediaDevices.getUserMedia({video: { facingMode: "environment" }, audio: true});
}

async function waitConnection(socket) {
    return new Promise((resolve) => {
        if (socket.readyState !== socket.OPEN) {
            socket.addEventListener("open", (_) => {
            resolve();
            })
        } else {
            resolve();
        }
    });
}


async function startStreaming(camera, videoElement, url) {
    const ws = new WebSocket(url);

    await waitConnection(ws);

    const recorder = RecordRTC(camera, {
        // audio, video, canvas, gif
        type: 'video',
        // audio/webm
        // video/webm;codecs=vp9
        // video/webm;codecs=vp8
        // video/webm;codecs=h264
        // video/x-matroska;codecs=avc1
        // video/mpeg -- NOT supported by any browser, yet
        // video/mp4  -- NOT supported by any browser, yet
        // audio/wav
        // audio/ogg  -- ONLY Firefox
        // demo: simple-demos/isTypeSupported.html
        //mimeType: 'video/webm;codecs=h264',
        MimeType: 'video/x-matroska;codecs=avc1',
        // MediaStreamRecorder, StereoAudioRecorder, WebAssemblyRecorder
        // CanvasRecorder, GifRecorder, WhammyRecorder
        //recorderType: WebAssemblyRecorder,
        recorderType: MediaStreamRecorder,

        // disable logs
        disableLogs: true,

        // get intervals based blobs
        // value in milliseconds
        timeSlice: 500,
        //bitrate: 1500000,
        videoBitsPerSecond: 1500000,
        // requires timeSlice above
        // returns blob via callback function
        ondataavailable: function(blob) {
            ws.send(blob)
        },
    })
    recorder.onStateChanged = function(state) {
        if (state == "stopped") {
            let interval = setInterval(function () {
                if (ws.bufferedAmount == 0) {
                    console.log("close");
                    ws.close();
                    clearInterval(interval);
                }
            }, 1000)
            
        }
    }
    recorder.startRecording();
    console.log(recorder);
    videoElement.srcObject = camera;
    videoElement.volume = 0;
    return recorder;
}

async function StartStreaming(camera, videoElement, data) {
    let response = await fetch(PeekastEndpoint, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
    if (response.ok) {
        let body = await response.json();
        return startStreaming(camera, videoElement, body.url);
    }
    return null;
}
export { StartStreaming, StartCamera };