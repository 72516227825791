import { useState } from "react";
import Home from "./pages/Home";
import Start from "./pages/Start";
import Streaming from "./pages/Streaming";
function App() {
  const [screen, setScreen] = useState("home");
  const [streamProps, setStreamProps] = useState(null);
  const [coordinates, setCoordinates] = useState(null);

    return (
    <div className="h-screen">
      {screen === "home" && <Home setCoordinates={setCoordinates} start={() => setScreen("streaming")}  setStreamProps={setStreamProps} />}
      {/* {screen === "startStream" && <Start start={() => setScreen("streaming")} setStreamProps={setStreamProps} />} */}
      {screen === "streaming" && <Streaming back={() => setScreen("home")}
      coords={coordinates} camera={streamProps.camera} infoStream={streamProps.infoStream}/> }
    </div>
  );
}

export default App;
 