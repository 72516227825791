import { useEffect, useRef, useState} from "react"
import Cancel from "../components/Cancel";
import { StartStreaming } from "../utils/peekast";
const Streaming = ({ camera, coords, infoStream, back }) => {
  const [recorder, setRecorder] = useState(null);
  const [toggle, setToggle] = useState(false);
  useEffect(() => {
      console.log("entre")
      if(camera === null) return;
      StartStreaming(camera, videoRef.current, {
        //type: "streams",
        //attributes: {
            // title: infoStream.title,
            // username: infoStream.username,
            // tags: infoStream.tags,
            // description: infoStream.description,
            protocol: "tcp",
//            rtmp_outputs: ["rtmp://a.rtmp.youtube.com/live2/y2uc-axv6-erww-rus9-agfq"]
            // lat: coords.latitude,
            // lon: coords.longitude
        //}
      }).then((response) => {console.log(response);setRecorder(response)} );
    }, [coords, camera, infoStream])
    const videoRef = useRef(null)
    const stopStream = async () => {
       await recorder.stopRecording();
       setToggle(!toggle);
       back()
    }
    return <div className="h-screen bg-black flex flex-col justify-between">
           <div className="relative flex justify-between px-2 items-start py-2">
              <button className="bg-black rounded-full text-white font-ibm uppercase w-20">live</button>
              <button className="bg-black text-white uppercase font-ibm rounded-full w-20">00:15:32</button>
           </div>
           <video className="h-full absolute"  playsInline autoPlay ref={videoRef} muted></video>
           <button className="w-10/12 mb-4 font-ibm mx-auto rounded-full
            text-white border h-10 px-10 border-white uppercase relative"
            onClick={() => setToggle(!toggle)}>finish stream</button>
            <Cancel state={toggle} stop={stopStream} close={()=>setToggle(!toggle)}/>
    </div> 
}
export default Streaming;